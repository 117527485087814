import { http } from '@/http/axios.js';
// 变更收货地址
export function updateOrderAddressAPI(data) {
    return http({
        url: '/admin/order/edit/address',
        method: 'post',
        data,
    });
}
// 变更订单商品
export function updateOrderGoodsAPI(data) {
    return http({
        url: '/admin/order/edit/goods',
        method: 'post',
        data,
    });
}
// 商品列表
export function getGoodsListAPI(params) {
    return http({
        url: '/admin/goods/page',
        method: 'get',
        params,
    });
}
// 商品详情
export function getGoodsDetailAPI(id) {
    return http({
        url: '/admin/goods/detail',
        method: 'get',
        params: { id },
    });
}
// 分配客服
export function getKfOrderDrawAPI(data) {
    return http({
        url: '/admin/kf/order/allocate',
        method: 'post',
        data,
    });
}
// 客服列表
export function getUserListAPI(params = {}) {
    return http({
        url: '/admin/user/list',
        method: 'get',
        params,
    });
}
// 备注列表
export function getRemarkListAPI(params) {
    return http({
        url: '/admin/order/trace/page',
        method: 'get',
        params,
    });
}
// 添加备注
export function handleAddRemarkAPI(data) {
    return http({
        url: '/admin/order/trace/add',
        method: 'post',
        data,
    });
}
// 用户评分
export function handleMemberGradeAPI(data) {
    return http({
        url: '/admin/member/grade',
        method: 'post',
        data,
    });
}
// 紧急联系人列表
export function getRelationListAPI(memberId) {
    return http({
        url: '/admin/member/relation/list',
        method: 'get',
        params: { memberId },
    });
}
// 校验紧急联系人
export function riskRelationAPI(data) {
    return http({
        url: '/admin/member/relation/risk/alert',
        method: 'post',
        data,
    });
}
// 添加紧急联系人
export function addRelationAPI(data) {
    return http({
        url: '/admin/member/relation/add',
        method: 'post',
        data,
    });
}
// 编辑紧急联系人
export function editRelationAPI(data) {
    return http({
        url: '/admin/member/relation/edit',
        method: 'post',
        data,
    });
}
// 紧急联系人详情
export function getRelationDetailAPI(id) {
    return http({
        url: '/admin/member/relation/detail',
        method: 'get',
        params: { id },
    });
}
// 删除紧急联系人
export function delRelationAPI(id) {
    return http({
        url: '/admin/member/relation/delete',
        method: 'post',
        data: { id },
    });
}
// 保存工作单位
export function saveMemberProfileAPI(data) {
    return http({
        url: '/admin/member/profile/save',
        method: 'post',
        data,
    });
}
// 是否监管
export function handleEnableAbmAPI(data) {
    return http({
        url: '/admin/order/edit/abm',
        method: 'post',
        data,
    });
}
// 修改零售价
export function updatePriceAPI(data) {
    return http({
        url: '/admin/order/salePrice/edit',
        method: 'post',
        data,
    });
}
// 物流详情
export function getDeliveryDetailAPI(orderNo) {
    return http({
        url: '/admin/order/delivery/trace',
        method: 'get',
        params: { orderNo },
    });
}
// 门店待结算金额计算
export function calcStoreSettleAPI(data) {
    return http({
        url: '/admin/order/store/settle/calc',
        method: 'post',
        data,
    });
}
//门店结算金额保存
export function calcStoreSaveApi(data) {
    return http({
        url: '/admin/order/store/settle/save',
        method: 'post',
        data,
    });
}
//门店待结算金额明细
export function settlementAmountDetails(params) {
    return http({ url: '/admin/order/store/settle/info', method: 'get', params });
}

// 评分明细列表
export function getScoreBillInfoAPI(params) {
    return http({
        url: '/admin/order/score/bill/list',
        method: 'get',
        params,
    });
}
